@font-face {
	font-family: 'SportsWorldRegular';
	src: url('../fonts/SportsWorldRegular/SportsWorldRegular.eot');
	src: url('../fonts/SportsWorldRegular/SportsWorldRegular.eot?#iefix')
			format('embedded-opentype'),
		url('../fonts/SportsWorldRegular/SportsWorldRegular.woff')
			format('woff'),
		url('../fonts/SportsWorldRegular/SportsWorldRegular.ttf')
			format('truetype');
	font-style: normal;
	font-weight: normal;
}
