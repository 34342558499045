@import '../node_modules/bootstrap/scss/bootstrap.scss';

.container {
	@extend .container;
}

.container-fluid {
	@extend .container-fluid;
}

.row {
	@extend .row;
}
