@import "./vars";

/*---- mixins ----*/
@mixin scrollbars($size, $foreground-color, $foreground-color-hover, $background-color: mix($foreground-color, white,  50%), $radius: 0) {
  // Usage: @include scrollbars(10px, #1f1f1f, #5e738b, #f1f1f1, 0);
  // For Google Chrome
  &::-webkit-scrollbar {
    width:  $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: $radius;
    background: $foreground-color;
  }
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $foreground-color-hover;
  }

  &::-webkit-scrollbar-track {
    border-radius: $radius;
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
/*---- mixins END ----*/

/*Border Component END*/
html,
body {
  width: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Monsterrat', sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  background-color: var(--color-background);
  @include scrollbars(15px, var(--color-2), var(--color-3), var(--color-11), 0);
}

.auth-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--color-11);
  input{
    border-radius: 15px;
    height: 50px;
    background-color: var(--color-11);
    border: 1px solid var(--color-white);
    color: var(--color-white);
    width: 48%;
    font-size: 20px;
    padding-left: 5px;
    &::placeholder{
      padding-left: 5px;
      color: var(--color-white);
    }
  }
}

.active__selection::marker,.active__genre::marker{
  color: var(--color-13);
}
.active__selection,.active__genre{
  font-weight: 700;
  color: var(--color-13);
  font-size: 18px;
  margin-bottom: 5px;
} 

.active__selection{
  margin-bottom: 80px;
}

.p-5{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.resultsContainer {
  border-radius: 15px;
  width: 62%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  a.button {
    width: 48%;
    text-align: center;
    align-self: center;
    padding: 20px;
    background-color: #2c3e50;
    color: var(--color-white);
    font-size: 20px;
    margin-bottom: 20px;
    margin-top: 20px;
    border-radius: 15px;
  }

  a.share {
    right: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100% - 2px);
    width: 26px;

    i{
      font-size: 25px;
      color: var(--color-5);
      transition: all 0.5s;
      right: 5px;
    }

    //&::before {
    //  position: relative;
    //  top: 0;
    //  display: inline-block;
    //  font-family: 'Glyphicons Halflings';
    //  content: '\e005';
    //  font-style: normal;
    //  font-weight: 400;
    //  line-height: 1;
    //  -webkit-font-smoothing: antialiased;
    //  -moz-osx-font-smoothing: grayscale;
    //  text-rendering: auto;
    //  font-size: 17px;
    //  color: var(--color-5);
    //  transition: all 0.5s;
    //}
    &:hover {
      text-decoration: none;
      transition: all 0.5s;

      &::before,
      i {
        color: var(--color-1);
        transition: all 0.6s;
      }
    }
  }

  a.link {
    &::before {
      transition: all 0.5s;
    }

    &:hover {
      text-decoration: none;
      transition: all 0.5s;

      &::before {
        color: var(--color-1);
        transition: all 0.6s;
      }
    }
  }
}


.auth-1 {
  font-size: 18px;
  padding: 10px;
  border-radius: 8px 8px 0 0;
  color: var(--color-white);
}

.auth-2 {
  background-color: var(--color-1);
  padding: 0 10px;
}

.auth-3 {
  background-color: var(--color-1);
  padding: 10px;
  margin-bottom: 50px;
  border-radius: 0 0 8px 8px;
}

.colorBackgroundGradient {
  background: var(--background-gradient);
}

.container {
  max-width: 95vw;
  margin: 0 auto;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  position: relative;
}

.menu-top {
  padding-top: 20px;
}

.musicTop-head {
  background-color: #3699ff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.shadowBox {
  box-shadow: rgba(0, 0, 0, 0.1) 0 20px 25px -5px, rgba(0, 0, 0, 0.04) 0 10px 10px -5px;
}

.footerBox-bottom {
  position: relative;
}

.footerBox {
  .Row {
    width: 100%;
  }
}

.box-menu-padding-0 {
  padding-left: 0;
  padding-right: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.block-title {
  margin-bottom: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.famousTrackBox {
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.SocialBox {
  min-height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.SocialBox--btnBox {
  position: relative;
  padding: 5px;
}

.logo {
  min-height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  display: flex;
  align-items: center;

  a {
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    line-height: normal;
    color: #fff;
    text-decoration: none;
    font-size: 50px;
    padding: 5px;

    &:hover {
      i {
        transition: all 0.6s;
      }
    }

    i {
      margin-right: 10px;
      transition: all 0.5s;
    }
  }
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logoLink {
  font-family: var(--custom-font-family);
}

/*Border Component*/
.borderBox {
  --width: 30%;
  --height: 70%;

  > * {
    background: #f8f8f8;
    position: relative;
    z-index: 2;
  }

  &::before {
    position: absolute;
    content: '';
    width: var(--width);
    height: var(--height);
    background: var(--background-gradient);
    top: -4px;
    left: -4px;
  }

  &::after {
    position: absolute;
    content: '';
    width: var(--width);
    height: var(--height);
    background: var(--background-gradient);
    right: -4px;
    bottom: -4px;
  }

  &.height-80 {
    --width: 20%;
    --height: 80%;
  }

  &.white-background-line {
    &::before {
      background: var(--color-white);
    }

    &::after {
      background: var(--color-white);
    }
  }

  &.revers-background {
    &::before {
      background: var(--background-gradient-inverse);
    }

    &::after {
      background: var(--background-gradient-inverse);
    }
  }

  &.revers-width {
    &::before {
      width: var(--height);
      height: var(--width);
    }

    &::after {
      width: var(--height);
      height: var(--width);
    }
  }

  &.middle-Border {
    &::before {
      top: -2px;
      left: -2px;
    }

    &::after {
      right: -2px;
      bottom: -2px;
    }
  }

  &.middle-Border-revers {
    &::before {
      top: auto;
      bottom: -2px;
      left: -2px;
    }

    &::after {
      right: -2px;
      bottom: auto;
      top: -2px;
    }
  }

  &.small-Border {
    &::before {
      top: -1px;
      left: -1px;
    }

    &::after {
      right: -1px;
      bottom: -1px;
    }
  }
}
/*Border Component END*/

.transform-rotateBox {
  transition: all 0.5s;

  &:hover {
    transform: rotate(-30deg);
    transition: all 0.6s;
  }
}

.musicSearch-contaniner {
  height: auto;

  input {
    -webkit-appearance: none;
    outline: none;
  }
}

.OneSearchWrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;

  button {
    -webkit-appearance: none;
    outline: none;
  }

  #codeint {
    display: none;
  }
}



.box{
  display: flex;
  justify-content: space-between;
}

.SearchWrapperBox {
  border-radius: 15px;
  box-sizing: border-box;
  width: 50%;
  min-width: 300px;
  border: 15px solid var(--color-14);
}

.inp {
  padding-right: 40px;
  background-color: transparent;
  border-radius: 15px;
}

.string {
  box-sizing: border-box;
  width: 100%;
  border: 0;
  padding: 7px 10px;
  font-size: 14px;
  background-color: var(--color-11);
  color: #fff;
  height: 40px;
  &::placeholder{
    color: #fff;
  }

  &:focus {
    outline: none;
  }
}

.searchArtist {
  position: absolute;
  top: 0;
  right: 80px;
  font-size: 14px;
  padding: 8px 7px;
  width: 144px;
  outline: none;
  background-color: #e7e7e7;
  cursor: pointer;
  color: #656565;
  border: 0;
  transition: all 0.2s ease;

  option {
    &:hover {
      background-color: #e7e7e7;
      color: #656565;
    }
  }
}

.siteTopWrapper {
  display: flex;
  flex-direction: column;
  border-bottom: 2px solid var(--color-6);
}

#trackAjax {
  margin-top: 15px;
  margin-bottom: 10px;
  background: var(--color-background);
  z-index: 1;

  .Row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
}

.topHeaderBox {
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.submit {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 16px;
  padding: 7px 0;
  width: 40px;
  cursor: pointer;
  color: var(--color-white);
  transition: all 0.2s ease;
  //border: 1px solid var(--color-white);
  border: none;
  height: 100%;
  background: var(--color-11);
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    margin-right: 3px;
    transition: all 0.5s;
  }

  &:hover {
    i {
      transition: all 0.6s;
    }
  }
}

#searchform {
  position: relative;
}

.navbar-dark {
  .navbar-brand {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-2);
    text-shadow: 1px 1px 0 var(--color-white), 2px 2px 0 var(--color-2);
  }

  .navbar-nav {
    > li {
      > a {
        color: var(--color-white);
      }
    }
    > .nav-item{
      .nav-link {
        display: flex;
        align-items: center;
        height: 50px;
        color: var(--color-white);
        position: relative;
        &::before{
          display: inline-block;
          position: absolute;
          content: '';
          right: -1px;
          width: 2px;
          height: 50%;
          background-color: var(--color-1);
          top: calc(25%);
        }
        &.home-link{
          display: flex;
          align-items: center;
          justify-content: center;
          // i{

          // }
          span{
            margin-left: 5px;
          }
        }

        &:focus {
          color: #333;
          background-color: var(--color-3);
        }

        &:hover {
          color: var(--color-white);
          background-color: var(--color-3);
        }
      }
      &:last-child{
        .nav-link{
          &::before{
            display: none;
          }
        }
      }
    }


    .show {
      .nav-link {
        color: var(--color-8);
        &.dropdown-toggle{
          color: var(--color-white);
        }

        &:focus {
          background-color: var(--color-3);
        }

        &:hover {
          background-color: var(--color-3);
        }
      }
    }

    .dropdown-menu {
      .nav-link {
        height: auto;
        padding: 3px 20px;
      }
    }
  }

  .navbar-toggler {
    border-color: #ddd;
  }
}

.navbar-brand {
  i {
    margin-right: 5px;
  }
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.navbar {
  position: relative;
  width: 100%;
  margin-bottom: 0;
  padding: 0 1rem;
  border-radius: 0;
  border: none;
  background-color: var(--color-2);
  //box-shadow: rgb(29, 43, 58) 0 0 5px 2px inset;
  .navbar-collapse {
    .dropdown-menu {
      &.show {
        border-radius: 0;
      }
    }
  }
  .SocialBox--btnBox{
    border-color: var(--color-1);
  }
}

.ya-share2__list.ya-share2__list_direction_horizontal{
  display: flex;
}

.navbar-toggler {
  position: relative;
  padding: 6px 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
  font-size: 14px;
}

.menu-left {
  min-height: 120px;
}

.genres {
  display: block;
  padding-top: 10px;
  padding-bottom: 5px;

  a {
    font-size: 15px;
    display: inline-block;
    padding: 2px 5px;
    background-color: #117e0b;
    margin: 0 5px 5px 0;
    color: var(--color-white);
    transition: all 0.15s ease;
    text-decoration: none;
    border: 1px solid var(--color-white);

    &:hover {
      background-color: #f64e60 !important;
      color: var(--color-white) !important;
      text-decoration: none;
    }
  }
}

.selected {
  background-color: #f64e60 !important;
  color: var(--color-white) !important;
  text-decoration: none;
}

.content-list-sounds-mew {
  margin-bottom: 20px;
}

.glyphicon {
  top: 0;
  vertical-align: text-top;
}

.dark--siteFooter {
  padding: 50px 50px 50px;
  color: var(--color-2);

  p {
    span {
      font-family: var(--custom-font-family);
    }

    margin: 10px 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  p.leftBoxLine {
    span {
      margin-right: 3px;
      margin-left: 6px;

      &.logoLink{
        font-size: 20px;
      }
    }
  }

  .dark--siteFooter--infoBox {
    width: fit-content;
    text-align: right;
    font-family: var(--custom-font-family);
    margin: 10px 0;
    display: flex;
    align-items: center;
  }
}

.mail-to{
  display: flex;
  align-items: center;
  a{
    color: var(--color-13);
  }
  i{
    margin: 0 5px;
  }
}

.footerTapLink {
  font-size: 25px;
  color: var(--color-5);

  &:hover {
    text-decoration: none;
    color: var(--color-5);
  }
}

.t-align-right {
  text-align: right;
}

.margin-bottom-60px {
  margin-bottom: 60px;
}

.list-search {
  display: none;
}

.topHeaderBox-site {
  margin-top: 150px;
}

.GeneralBox {
  z-index: 99999;
  top: auto;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  display: flex;
  width: 100%;
  align-items: flex-start;
  margin: 0 auto;
  height: 120px;
  padding: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  background: var(--color-11);
  transition: all 0.6s ease;
}

.volumeBox {
  cursor: pointer;
  padding: 0;
  overflow: hidden;
  display: inline-block !important;
  width: 100%;
  height: 18px;
  background: var(--color-white);
  vertical-align: top;
  // float: right;
  margin-top: 0;
  border-radius: 5px;
  border: 2px solid var(--color-white);
  //border-radius: 10px;

  .volumeLine {
    cursor: pointer;
    width: 40%;
    height: 18px;
    float: left;
    -webkit-transition: width 0.6s ease;
    transition: width 0.6s ease;
    background: var(--color-2);
    //border-radius: 10px;
  }
}

.artist-one {
  font-size: 18px;
  font-weight: 700;
}

.artist-two {
  font-size: 18px;
  font-weight: 700;
}

.famousTrackTitleContainer {
  width: 100%;
  background-color: var(--color-11);
  padding: 10px;
  border-radius: 15px;
  //border-radius: 8px;
  // .titleLine {
  //   //border-bottom: 1px solid var(--color-2);
  // }

  p {
    margin: 15px 0;
    color: var(--color-5);
  }
}
.description-box{
  margin-bottom: 30px;
  font-size: 20px;
  // background-color: var(--color-11);
  p {
    margin: 15px 0;
    color: var(--color-5);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    span{
      margin: 0 5px;
      text-align: center;
      &::before{
        content: open-quote;
        color: var(--color-white);
        margin-right: 5px;
      }
      &::after{
        content: close-quote;
        color: var(--color-white);
        margin-left: 5px;
      }
    }
    i{
      font-size: 25px;
      margin-right: 10px;
    }
  }
}

.btn:not(:disabled):not(.disabled){
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}


.famous-container_background{
  border-radius: 15px;
  background-color: var(--color-11);
  padding: 15px;
  margin-bottom: 20px;
  h2{
    color: var(--color-2);
    margin: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      margin-right: 15px;
    }
  }

  .famous-container{
    width: 100%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    a{
      &.famous-track_box{
        border-radius: 15px;
        display: inline-flex;
        align-items: center;
        width: 23%;
        height: 80px;
        align-items: center;
        border: 1px solid var(--color-white);
        color: var(--color-white);
        margin: 5px;
        padding: 0 15px;
        margin-bottom: 10px;
        &:hover{
          text-decoration: none;
        }
        .famous-track_box--image{
          height: 50%;
          margin-right: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
         
          color: var(--color-white);
          i{
            font-size: 30px;
          }
        }
        .famous-track_box--title{
          overflow: hidden;
          line-height: 20px;
          font-size: 15px;
          text-overflow: ellipsis;
        }
      }
    }
  }
}


.famousTrackLine {
  margin: 15px 15px;
  font-weight: 700;
  color: var(--color-2);

  i {
    margin-right: 5px;
  }
  span{

    &::before{
      content: open-quote;
      color: var(--color-white);
      margin-right: 10px;
    }
    &::after{
      content: close-quote;
      color: var(--color-white);
      margin-left: 10px;
    }
  }
}

.seoTitleBox {
  p {
    span {
      text-transform: uppercase;
      font-weight: 700;
    }
  }
}

.resultTrackHeaderContainer {
  display: flex;
  align-items: center;
  min-height: 40px;
  border-bottom: 1px solid var(--color-5);
  margin-bottom: 10px;
  font-size: 20px;
}

.play-box {
  width: 50px;
  height: 39px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.track-name-box {
  width: calc(100%);
  color: var(--color-6);
  //height: 20px;
}

.time-box {
  width: 65px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.share-box {
  width: 38px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}

.download-box {
  width: 42px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-6);
}


.TrackBoxResult {
  cursor: pointer;
  font-weight: 700;
  font-size: 18px !important;
  height: 80px;
  border-radius: 15px;
  display: inline-flex;
  align-items: center;
  position: relative;
  transition: all 0.15s ease;
  margin-bottom: 10px;
  background: var(--color-11);
  //border-radius: 8px;
  //box-shadow: var(--color-2) 0 1px 0;

  &:last-child {
    margin-bottom: 14px;
  }

  &:hover {
    background: var(--color-13);

    h2 {
      .title {
        color: var(--color-white);

        a {
          color: var(--color-white);
        }
      }
    }

    .left-b {
      color: var(--color-white);
    }

    a.share {
      &::before,
      i{
        color: var(--color-white);
      }
    }

    a.link {
      &::before,
      i{
        color: var(--color-white);
      }
    }
  }

  a.start {
    cursor: pointer;
    width: 38px;
    height: 38px;
    background-color: var(--color-13);
    //border-radius: 50%;
    border: 1px solid var(--color-13);
    transition: all 0.8s ease;
    margin-left: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    margin-right: 10px;
    color: var(--color-white);
    border-radius: 50%;

    &::before {
      position: relative;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e072';
      margin-left: 2px;
    }

    &:hover {
      background-color: var(--color-3);
      transition: all 0.2s ease;
      text-decoration: none;
      &::before{
        color: var(--color-2);
      }
    }
  }

  a.link {
    cursor: pointer;
    //position: absolute;
    right: 1px;
    left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    height: calc(100% - 2px);
    width: 35px;
    border-radius: 0;
    i{
      font-size: 22px;
      color: var(--color-5);
    }

    //&::before {
    //  position: relative;
    //  top: 0;
    //  display: inline-block;
    //  font-family: 'Glyphicons Halflings';
    //  content: '\e202';
    //  font-style: normal;
    //  font-weight: 400;
    //  line-height: 1;
    //  -webkit-font-smoothing: antialiased;
    //  -moz-osx-font-smoothing: grayscale;
    //  text-rendering: auto;
    //  color: var(--color-2);
    //  font-size: 22px;
    //}

    &:hover {
      text-decoration: none;
      transition: all 0.2s ease;

      //&::before {
      //  color: var(--color-white);
      //}
      i{
        color: var(--color-white);
      }
    }
  }

  h2 {
    font-size: 1em;
    margin: 0 0 0 10px;
    line-height: normal;
    overflow: hidden;
    width: calc(100% - 158px);
    align-items: center;
    display: flex;
    height: 55px;
  }
}
// .TrackBoxResult .plays{
//   a.start {
//     &::before {
//       position: relative;
//       display: inline-block;
//       font-family: 'Glyphicons Halflings';
//       font-style: normal;
//       font-weight: 400;
//       line-height: 1;
//       -webkit-font-smoothing: antialiased;
//       -moz-osx-font-smoothing: grayscale;
//       content: '\e073';
//       margin-left: 2px;
//     }
//   }
// }

.TrackBoxResult.pausing {
  a.start {
    &::before {
      position: relative;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\e072';
      margin-left: 2px;
    }
  }

  background: var(--color-14);

  h2 {
    .title {
      color: var(--color-white);

      a {
        color: var(--color-white);
      }
    }
  }

  .left-b {
    color: var(--color-white);
  }

  a.share {
    &::before,
    i{
      color: var(--color-white);
    }
  }

  a.link {
    &::before,
    i{
      color: var(--color-white);
    }
  }
}

.TrackBoxResult.plays {
  a.start {
    &::before {
      content: '\e073';
      transform: rotate(0deg);
    }
  }

  background: var(--color-14);

  .start {
    border: 1px solid var(--color-14);
  }

  .pausing {
    border: 1px solid var(--color-14);
  }

  h2 {
    .title {
      color: var(--color-white);

      a {
        color: var(--color-white);
      }
    }
  }

  .left-b {
    color: var(--color-white);
  }

  a.share {
    &::before,
    i{
      color: var(--color-white);
    }
  }

  a.link {
    &::before,
    i{
      color: var(--color-white);
    }
  }
}

  .left-b {
    //position: absolute;
    right: 67px;
    left: auto;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 13px;
    color: var(--color-2);
    height: calc(100% - 2px);
    display: flex;
    align-items: center;

    .dur {
      margin-right: 0;
      font-weight: 700;
      position: relative;
      line-height: normal;

      &::before {
        position: relative;
        margin-right: 5px;
        display: inline-block;
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #3699ff;
      }
    }
  }



h2 {
  .title {
    font-weight: normal;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    > span{
      color: var(--color-white);
    }

    a {
      color: var(--color-white);
      font-weight: 700;

      &:first-child {
        padding-right: 3px;
      }

      &:last-child {
        padding-left: 3px;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
}

a.play {
  color: var(--color-white);
  //border: none;

  &:hover {
    text-decoration: none;
  }
}

a.pause {
  color: var(--color-white);

  &:hover {
    text-decoration: none;
  }
}


.play,
.pause{
  width: 54px;
  height: 54px;
  margin: 0 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-size: 30px;
  background-color: var(--color-11);
  &::before{
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-left: 3px;
  }

}
.play {
  //border-radius: 50%;
  //border: 2px solid var(--color-white);
  &::before {
    content: '\e072';
  }
}
.pause {
  border: 1px solid var(--color-2);
  &::before {
    content: '\e073';
  }
}

.playBox--LeftBtn,
.playBox--rightBtn{
  width: 38px;
  height: 40px;
  margin: 0 -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 50%;
  font-size: 25px;

  &::before{
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}
// .playBox--LeftBtn{
//   // &::before{
//   //   //content: '\e079';
//   // }
// }
// .playBox--rightBtn{
//   // &::before{
//   //   //content: '\e080';
//   // }
// }

.Box--CenterPlayBox {
  width: 150px;
  height: 100%;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  margin-bottom: 15px;
}

.ProgressBarContainer {
  cursor: pointer;
  transition: height linear 0.3s;
  position: absolute;
  top: 0;
  bottom: auto;
  height: 11px;
  background-color: #fff;
  right: 0;
  color: #000;
  font-size: 10px;
  width: 100%;
  //border-radius: 10px;
}

.progressBarContainer--downloadBar{
  display: block;
  height: 10px;
  width: 100px;
  z-index: 201;
}

.progressBarContainer--playBar {
  position: relative;
  display: inline-block;
  height: 11px;
  background: var(--color-2);
  color: var(--color-2);
  //border-radius: 10px;
  &::before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    width: 6px;
    height: calc(100% + 10px);
    background: var(--color-white);
    border: 2px solid #061161;
    display: none;
  }
}

.player--Box {
  position: relative;
  //height: calc(100% - 20px);
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 10px 0 0;
}

.player--Box--trackTimeBox {
  color: var(--color-white);
  display: flex;
  justify-content: space-between;
  width: calc(190px);
  position: relative;
  font-size: 18px;
  //left: calc(50% - 100px);
  //top: calc(50% + 18px);
  > span {
    i {
      margin-right: 5px;
    }
  }
}

.player--Box--trackTimeBox span{
  display: flex;
  align-items: center;
}

.currentTime {
  line-height: normal;
  font-size: 25px;
  font-family: var(--custom-font-family);
  text-align: right;
  left: 0;
  margin-right: 5px;
  top: auto;
  color: var(--color-1);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

.timeBox {
  margin-left: 5px;
  line-height: normal;
  font-size: 25px;
  font-family: var(--custom-font-family);
  text-align: right;
  right: 0;
  left: auto;
  color: var(--color-1);
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.5);
}

.logoLink{
  font-family: var(--custom-font-family);
}

.playerTrack {
  display: block;
  margin-left: 0;
  font-size: 17px;
  overflow: hidden;
  color: var(--color-white);
  text-overflow: ellipsis;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
}

.rightActionBox {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-around;
  .SocialBox{
    min-height: 36px;
    justify-content: flex-start;
    .SocialBox--btnBox{
      border-color: var(--color-white);
    }
  }

  &-muteUnmute{
    width: 250px;
    margin-left: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.loaded {
  display: none;
}

.uil {
  cursor: pointer;
  transition: all 0.2s ease;
  //background-color: var(--color-7);

  &:hover {
    transition: all 1.2s ease;
  }
}

a.uil {
  color: var(--color-white);
  font-size: 35px;
  &:hover {
    text-decoration: none;
  }
}

.uil.pause {
  //background-color: rgba(6, 23, 0, 0.6);
  //border: 2px solid var(--color-1);

  &:hover {
    background-color: transparent;
    //border: 2px solid var(--color-2);
  }
}

.uil.playBox--rightBtn {
  border-left: none;
  border-bottom: none;
}

.uil.playBox--LeftBtn {
  border-right: none;
  border-top: none;
  & i{
    font-size: 35px;
  }
}

.Box__central{
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.play, .pause{
  border-radius: 90px;
  border: 4px solid #fff;
}

.player--Box--leftWrapper {
  width: 30%;
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .player__Collapse{
    display: none;
  }
  .playerTime__box{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
  }
}

.playerTrack__box{
  width: 30%;
  margin-left: 50px;
  display: flex;
  align-items: center;
}

.playerTrack--icon {
  width: 35px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  //border: 2px solid var(--color-white);
  font-size: 30px;
  margin-right: 15px;

  i {
    color: var(--color-white);
  }
}

a.uir {
  &:hover {
    text-decoration: none;
  }
}

.uir {
  float: left;
  cursor: pointer;
  width: 40px;
  height: 24px;
  margin-right: 5px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;

  &:hover {
    opacity: 1;
  }
}

.repeat {
  margin-right: 30px;

  &::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.repeatOff {
  &::before {
    display: inline-block;
    font: normal normal normal 14px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  margin-right: 30px;
  opacity: 1;
}


.mute,
.unmute{
  &::before{
    position: relative;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 30px;
    color: var(--color-white);
  }
}
.mute {
  &::before {
    content: '\e038';
  }
}
.unmute {
  &::before {
    content: '\e036';
  }
}

.topHeaderBox--socialBox {
  min-width: 50%;
}

.janry-musiki {
  text-align: center;
}

.site-left-gorizont-menu {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.nav-stacked {
  > li {
    display: block;
    border-bottom: 1px solid var(--color-6);

    + {
      li {
        margin-top: 0;
      }
    }
  }
}

.AsideBox {
  > li {
    > a {
      border-radius: 0;
    }
  }

  .Aside-content {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    h2 {
      overflow: visible;
      line-height: normal;
      width: 100%;
      padding: 0 5px;
      margin: 15px 0 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 20px;
      color: var(--color-6);

      .FooterWrapper & {
        display: flex;
        justify-content: space-between;
        color: var(--color-13);
      }

      &:first-child {
        margin: 0 0 5px;
      }

      .glyphicon {
        width: 30px;
        height: 30px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        color: var(--color-6);
        font-size: 17px;
      }
    }

    a {
      font-size: 15px;
      display: inline-flex;
      padding: 2px 5px;
      width: 100%;
      margin: 0 5px 5px 0;
      color: var(--color-5);
      text-decoration: none;
      border-bottom: 1px solid transparent;
      transition: 0.5s linear;
    }
  }

  .Aside-content.today-Download {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.list-sounds-new {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.nav {
  > li {
    > a {
      color: #444;
    }
  }
}

.footerSidebar {
  margin-bottom: 15px;

  .Aside-content--box-list {
    padding: 5px;
  }
}

.AsideBox.footerSidebar {
  .Aside-content {
    a {
      width: auto;
      border-bottom: 1px solid var(--color-2);
    }
  }
}

.Aside-content {
  a {
    &:hover {
      color: var(--color-1);
      text-decoration: none;
      //border-color: var(--color-5);
      background-color: var(--color-2);
    }
  }
}

.Aside-content--margin {
  width: 100%;
  margin-bottom: 15px;
}
.Aside-content--menu-list{
  padding: 3px 0;
  background-color: red;
}

.Aside-content--box-list {
  background: var(--color-11);
  padding: 3px 0;
  //border-radius: 8px;
  a {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.Aside-content--box-list.siteDotted-list {
  &.dotted-headphones{
    a{
      &::before{
        content: '\E035';
      }
    }
  }
  &.dotted-ok{
    a{
      &::before{
        content: '\E013';
      }
    }
  }
  &.dotted-qrcode{
    a{
      &::before{
        content: '\E039';
      }
    }
  }

  a {
    position: relative;
    padding-left: 20px;

    &:hover {
      &::before {
        color: var(--color-1);
      }
    }

    &::before {
      top: calc(50% - 7px);
      position: absolute;
      left: 4px;
      display: inline-block;
      font-family: 'Glyphicons Halflings';
      font-style: normal;
      font-weight: 400;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\E029';
      font-size: 14px;
      color: transparent;
    }
  }
}

.verticalLine{
  &::before{
    content: '';
    position: absolute;
    top: 10%;
    right: -1px;
    width: 2px;
    height: 80%;
    background-color: #d2d2d2;
  }
}

.Aside-content--box-list.honeycomb-mod {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  a {
    width: 100%;
    border: 1px solid rgba(82, 194, 52, 0.4);
    margin: 2px 5px;

    &:last-child {
      margin-bottom: 5px;
    }

    &:hover {
      border-bottom-color: rgba(6, 23, 0, 0.5);
    }
  }

  a.w-30 {
    width: calc(100% / 3 - 10px);

    &:hover {
      box-shadow: 200px 0 0 0 rgba(82, 194, 52, 0.3) inset;
    }
  }

  a.w-50 {
    width: calc(100% / 2 - 10px);

    &:hover {
      box-shadow: 300px 0 0 0 rgba(82, 194, 52, 0.3) inset;
    }
  }
}

.Aside-content--box-list.underline-link {
  a {
    border-bottom: 1px solid rgba(82, 194, 52, 0.4);

    &:hover {
      border-bottom-color: rgba(6, 23, 0, 0.5);
    }

    &:last-child {
      border-bottom-color: transparent;
    }
  }
}

.headerWrapper {
  position: relative;
}

.menu-box {
  position: relative;
  top: 10px;
}

.height-100P {
  height: 100%;
}

.background-color {
  background-color: var(--color-background);
}

.Aside--right {
  padding-top: 10px;
  padding-bottom: 10px;
}

.FooterWrapper {
  position: relative;
  color: var(--color-13);
  margin-bottom: 40px;
}

#GeneralBox,
#toTop{
  display: none;
}

.relative {
  position: relative;
}

.dropdown-menu {
  min-width: 160px;
  white-space: nowrap;

  .nav-link {
    &:focus {
      background-color: var(--color-3);
    }

    &:hover {
      background-color: var(--color-3);
    }
  }
}

.dropdown.show {
  .nav-link.dropdown-toggle {
    background-color: var(--color-3);
  }
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}

.copyright__box{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  font-size: 18px;
}


/*SHAREBOX*/
span{
  &.shareblock{
    border-radius: 15px;
    z-index: 10000;
    max-width: 40%;
    display: block;
    background-color: var(--color-white);
    position: fixed;
    top: 50%;
    left: 20%;
    margin: -100px 0 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    text-align: center;
    border: 1px solid var(--color-2);
    b {
      position: absolute;
      top: -13px;
      right: -13px;
      display: block;
      width: 27px;
      height: 27px;
      text-align: center;
      margin-bottom: -24px;
      background-color: #dddddd;
      cursor: pointer;
      border-radius: 30px;
      line-height: 26px;
    }
    h1 {
      padding: 10px 15px;
      margin: 0;
      background: var(--color-2);
      font-family: sans-serif;
      font-size: 20px;
      font-weight: 400;
      display: block;
      color: var(--color-white);
      border-bottom: 8px solid var(--color-3);
    }
    span {
      display: block;
      overflow: hidden;
      padding: 20px;
    }
    a {
      text-decoration: underline;
      display: block;
      float: left;
      height: 30px;
      line-height: 30px;
      // vertical-align: middle;
      font-size: 1.1em;
      background: #f2f2f2 url('../images/share.png') 5px 0 no-repeat;
      border-radius: 2px;
      -moz-border-radius: 2px;
      -webkit-border-radius: 2px;
      -khtml-border-radius: 2px;
      margin: 10px 10px 0 0;
      padding: 0 10px 0 26px;
      font-family: sans-serif;

      &.vk {
        background-position: 5px 0;
      }
      &.tw {
        background-position: 5px -60px;
      }
      &.ok {
        background-position: 5px -90px;
      }
      &.mm {
        background-position: 5px -120px;
      }
      &.gp {
        background-position: 5px -150px;
      }
    }
  }
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.wrapper {
  display: flex;
  align-items: stretch;
  overflow: hidden;
  margin-bottom: 120px;
}

span.logoLink{
  font-family: 'Caveat', cursive;
  font-size: 64px;
}

.music-menu{
  background-color: var(--color-11);
  border-radius: 15px;
  padding: 20px;
  margin-bottom: 25px;
  &__list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  &__list a{
    color: var(--color-2);
    position: relative;
    padding-bottom: 10px;
    margin-right: 20px;
    margin-bottom: 10px;
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
    &::after{
      content: '';
      height: 2px;
      width: 100%;
      background-color: var(--color-2);
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}

.list-unstyled__link{
  border: 1px solid var(--color-background);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 15px 0;
  border-radius: 10px;
  &:hover{
    background-color: var(--color-14);
    .download__vk{
      color: #fff !important;
    }
    & i{
      color: #fff;
    }
  }
  
  i{
    font-size: 25px;
  }
}

.list-unstyled > li:hover{
  background-color: var(--color-14);
}

.collapse li{
  position: relative;
  &::before {
    position: absolute;
    width: 100%;
    height: 2.5px;
    background-color: var(--color-13);
    bottom: 0;
    content: '';
  }
}

#sidebar {
  border-radius: 10px;
  margin-bottom: 10px;
  padding: 20px;
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--color-11);
  color: var(--color-white);
  transition: all 0.3s;
  & ul{
    color: var(--color-13);
    font-size: 16px;
    font-weight: 700;
    margin-top: 10px;
  }
  .sidebar-header {
    border-radius: 15px;
    padding: 20px 0 10px;
    background: var(--color-10);
    &__logo{
      a{
        color: var(--color-white);
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover{
          text-decoration: none;
        }
      }
    }
  }
  ul.components {
    padding: 20px 0;
  }
  ul {
    p {
      color: var(--color-white);
      padding: 10px;
      font-size: 18px;
    }
    li {
      a {
        padding: 10px;
        font-size: 1.1em;
        display: block;
        color: var(--color-white);
      }
    }
    li.active {
      >a {
        color: var(--color-white);
        background: var(--color-10);
      }
    }
  }
  a[aria-expanded="true"] {
    color: var(--color-13);
    position: relative;
    padding-bottom: 3px;
    &::before {
      position: absolute;
      width: 100%;
      height: 2.5px;
      background-color: var(--color-13);
      bottom: 0;
      content: '';
    }
  }
  a[data-toggle="collapse"] {
    position: relative;
    color: var(--color-13);
  }
  a[aria-expanded="false"] {
    &::before {
      content: '\e259';
      display: block;
      position: absolute;
      right: -15px;
      top: 7px;
      font-family: 'Glyphicons Halflings';
      font-size: 0.6em;
    }
  }
  ul {
    ul {
      a {
        font-size: 0.9em !important;
        padding-left: 30px !important;
        background: var(--color-10);
      }
    }
  }
  ul.CTAs {
    padding: 20px;
    a {
      text-align: center;
      font-size: 0.9em !important;
      display: block;
      margin-bottom: 10px;
      color: var(--color-13);
      padding: 0;
    }
  }
 
  a.article {
    background: var(--color-10) !important;
    color: var(--color-white) !important;
    &:hover {
      background: var(--color-10) !important;
      color: var(--color-white) !important;
    }
  }
  .SocialBox{
    justify-content: center;
  }
  #pageSubmenu2,
  #pageSubmenu1 {
    overflow: auto;
    @include scrollbars(10px, var(--color-2), var(--color-3), var(--color-11), 0);
  }
  .bottom{
    .bottom_header{
      padding: 5px;
      color: var(--color-2);
      .BoxLine{
        margin: 10px 0;
        .BoxLine_top{
          margin-bottom: 10px;
          padding-bottom: 10px;
          border-bottom: 1px solid var(--color-2);
        }

      }
    }
  }
}

.BoxLine_top p{
  margin-bottom: 10px;
}
.sidebarCollapse{
  min-height: 50px;
  min-width: 100px;
  background-color: var(--color-11);
  color: var(--color-white);
  font-size: 16px;
  border-radius: 0;

  //.rightActionBox & {
  //  background-color: var(--color-background);
  //}
  &:hover,
  &:active,
  &:focus{
    &:not(.disabled),
    &:not(:disabled){
      color: var(--color-11)!important;
      background-color: var(--color-2)!important;
      color: var(--color-white);
    .glyphicon-menu-hamburger:before{
      color: var(--color-11)!important;
      margin-right: 10px;
    }
    }
  }
}



.glyphicon-menu-hamburger:before{
  margin-right: 10px;
}

.glyphicon-cloud-download glyphicon-heart{
  right: 5px;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  //padding: 20px;
  min-height: 100vh;
  width: 100%;
  transition: all 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
// @media (max-width: 768px) {
//   #sidebar {
//     margin-left: -250px;
//   }
//   #sidebar.active {
//     margin-left: 0;
//   }
//   #sidebarCollapse span {
//     display: none;
//   }
// }

#oframepleer{display:none!important;} 
#pleer{height:auto!important;}